import { ERRORS } from "enumerations";
import regex from "enumerations/regex";
import * as yup from "yup";

export const fieldNames = {
	name: "name",
	note: "note",
	products: "products",
	hotel: "hotel",
	paymentType: "paymentType",
	products: "products",
	roomNumber: "roomNumber",
	status: "status",
	totalPrice: "totalPrice",
	orderNumber: "orderNumber",
	dollar: "dollar",
	euro: "euro",
	totalPriceWithoutFee: "totalPriceWithoutFee",
	serviceCharge: "trayCharge",
	buyPrice: "buyPrice",
	delivery: "delivery",
};

export const validation = (URL_ID) =>
	yup.object().shape({
		[fieldNames.name]: yup.string().label(fieldNames.name),
		[fieldNames.note]: yup.string().label(fieldNames.note),
	});
